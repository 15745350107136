import { EUsersActions, UsersActions } from "../actions/users.actions";
import { UsersState } from "../app.state";

export const initialState: UsersState = {
    users: []
};

export function usersReducer(state = initialState, action: UsersActions) {
    switch (action.type) {
        case EUsersActions.LOAD_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload,
            };
        case EUsersActions.ADD_USER_SUCCESS:
            return {
                ...state,
                users: action.payload,
            };
        case EUsersActions.DELETE_USER_SUCCESS:
            return {
                ...state,
                users: action.payload,
            };
        default:
            return state;
    }
}
