import { ESyncUserActions, SyncUserActions } from '../actions/sync-gateway-user.actions';

import { SyncGatewayUserState } from '../app.state';

export const initialState: SyncGatewayUserState = {
    syncGatewayUser: null,
    errorMessage: null
};

export function syncGatewayUserReducer(state = initialState, action: SyncUserActions) {
    switch (action.type) {
        case ESyncUserActions.SYNC_USER_LOAD: {
            return { ...state };
        }
        case ESyncUserActions.SYNC_USER_FETCH: {
            return { ...state };
        }
        case ESyncUserActions.SYNC_USER_FETCH_SUCCESS: {
            return {
                ...state,
                syncGatewayUser: action.payload,
                errorMessage: null
            };
        }
        case ESyncUserActions.SYNC_USER_FETCH_FAIL: {
            return {
                ...state,
                syncGatewayUser: null,
                errorMessage: "No sync user found"
            };
        }
        case ESyncUserActions.SYNC_USER_CREATE: {
            return { ...state };
        }
        case ESyncUserActions.SYNC_USER_CREATE_SUCCESS: {
            return {
                ...state,
                syncGatewayUser: action.payload,
                errorMessage: null
            };
        }
        case ESyncUserActions.SYNC_USER_CREATE_FAIL: {
            return {
                ...state,
                syncGatewayUser: null,
                errorMessage: "No sync user created"
            };
        }

        default:
            return state;
    }
}