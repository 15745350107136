export const environment = {
  production: true,
  baseApi: 'https://api.ledger-manager.com',
  apiPath: 'https://api.ledger-manager.com/api/v1/merchant/',
  syncUserApiPath: 'https://syncuser.ledger-manager.com/api/v1/',
  imgPath: 'https://api.ledger-manager.com/storage/',

  GOOGLE_MAP_API_KEY: 'AIzaSyD022FhP0v0x7Tx6bgrO5e8Yo_Xo4kU1Iw',

  localDB: 'ledgermandb',
  sync_gateway: {
    protocol: 'https',
    url: 'sync.ledger-manager.com/lmbucket',
    private_key:
      '-----BEGIN RSA PRIVATE KEY-----' +
      'MIIEpAIBAAKCAQEAphCXBbQBid7UrUeKpjhmRrLdZO5pGq3xUZ6kDaD0KSHPolUA' +
      'xjYICxBWSX077osflR+AoXoSYh7h/8e4zahJouWkSpwg2eix//5ry67DJQV4UHGu' +
      '0Lv/Q+ookrq7cCdG0vcn3b3pctynYsAQx2gEiLYWOgnWSxBmyU/wSIlWrl293GB3' +
      'FYLTJSXXPN7zJQ4Ur0Q+LrBVKbTvWSiA9NsAu5QmTzmCMy+1YWyD01rPW4KI46na' +
      'vQBvxC+x/McBb9xThApkTu+IMHCbf4pXRmvcDBtuZ/mUfRt+Kv34GtpPB9gpkxX8' +
      'JyzEd/dz4XQjmzvkArfeQ8LmnlxgemY4tUJCfQIDAQABAoIBACe1eddURrXoAaT5' +
      'oW7Dt7kaFhSHabyGcVjZ/VKA9UFMo3CmsMu0qdGzc16ZQpZ0z/WBlemG3hR2XAYk' +
      'hM0we4MnNFTe5oAUw1N6S95liKY2m6FovauN+KkNuev0GNPloGrbzL2aJtNfbesQ' +
      'EDMObV8fY0s3hPVoE5v5sHQ32vFQeFrbjcCJISdqdH2IrOTo7YrbCOgMh1LNfzDE' +
      'TDcoEqDrk/SeLRdQPRZIK1DZnSz4sSUHSRT21vFiGaodBICHUfqg2JOhzA90tnhv' +
      'refk6KUnyppRaqU4z9Ow418jQ9f8taJwZt/pcCGkgh/DEMvWIAI2MIxh5oZENz0j' +
      'quAigaECgYEA1+QzvIKP+6DWxrInERdxP49w2uiJM0n6Pnbu37tfSAdfaPY1+wy+' +
      'zWxgjDxtRqM2Vijq5rshh/x543VoLY9VxiK2PJDg/zUM2iyb58nEcuSRF672wFxg' +
      'GO9HzDvmH+NFhG7Fv9Wii+jVkz1eo2Nb5PbBqDCEhYbMg+oH5RPLZlkCgYEAxOqi' +
      'PUaxzB8t/GZeQdqMqz4aYzUTIY7iw1bGGj/2xF4tgT6lPeiFxt5V7SaN5zrbO8SO' +
      'iB7zLgVYSGyAx6pLFM5CaT3YsmwLuOA33ByA+tp137KO3FFsAIv9rDBk71SoHdyd' +
      'UdPpZKoslNJT7XVEQJS01r7tPYaqrUgyrUkQgMUCgYEAwhcoKBbdKSStUo8Brkz8' +
      'BF+J31RlXJXqg1PbZ4sFmzT9otiPKTeJJheEKS9+Mp4zEHzSykJ8X8wtLL/1u9f8' +
      'DhhQ69x4WxgviMnIFDeCBKTWiItEzSV/Q4uDaeqD9jEV43zPDasb6lqWADyRI6UF' +
      'DbCF5BZSXdUVcpbpKBqGp2kCgYA3206WDx2UBywmMmt+Rp4sXPtjNMTuefd87XpY' +
      'K9i1rvk9diWtP+3ikWLSWGNcOefCNmkv14engECgmpaVDIsrpa5U6AQ16EwS7xcp' +
      'SrSTc7CH+cBoYng+V3dnhnq7IMpnXhMKCRBlS5hFxruj2eW4Y/ZAC/yDvVVgveAS' +
      'I22Y+QKBgQDQDzDpiD/T/+T/IeaJvCtlVZs5lC6zKox3jIIIDzTrOFzSSVIKJlep' +
      'sdQJoI9Q5ai0PyHT7X/i5EWz4midRnigK1ExFrm66ybIpDS5NMmYO4sKyMeqG8rS' +
      'HnQR//RgtXpcJ8ZS9SubVmuI924lhZraGgA8Hef8TYjhg8zohD+PLQ==' +
      '-----END RSA PRIVATE KEY-----'
  },

  AWS: {
    BUCKET_NAME: "ledger-manager-prod-bucket",
    ACCESS_KEY: "AKIA3QFNCO4HJV5EZ3M5",
    SECRET_KEY: "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",
    REGION: "ap-southeast-1",
  },

  DateFormat: 'DD-MM-YYYY',
  DateTimeFormat: 'DD-MM-YYYY HH:mm:ss',
};
