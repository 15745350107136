import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Actions, Effect, ofType } from "@ngrx/effects";

import { of } from "rxjs";
import { map, switchMap, withLatestFrom } from "rxjs/operators";

import { IAppState } from "../app.state";
import { selectUsers } from "../selectors/users.selectors";
import { UsersService } from "app/account/users/services/users.service";
import {
    EUsersActions, AddUserSuccessAction, DeleteUserSuccessAction,
    LoadUsersSuccessAction, UsersFalseAction
} from "../actions/users.actions";

@Injectable()
export class UsersEffefcts {
    constructor(
        private actions$: Actions,
        private usersService: UsersService,
        private store: Store<IAppState>,
    ) { }

    @Effect()
    loadUsers$ = this.actions$.pipe(
        ofType(EUsersActions.LOAD_USERS),
        withLatestFrom(this.store.select(selectUsers)),
        switchMap((action: any[]) => {
            const existingUsers: any[] = [];
            for (let index = 0; index < action[1]?.length; index++) {
                const element = action[1][index];
                existingUsers.push(element)
            }
            if (!existingUsers?.length) {
                return this.usersService.getUsers()
                    .pipe(map((resposne: any) => {
                        const userList = [];
                        for (let index = 0; index < resposne['data']?.length; index++) {
                            const element = resposne['data'][index];
                            const user = {
                                phone_number: element['phone'],
                                role: element['sharedBusinesses'][0]['role']
                            };
                            userList.push(user);
                        }
                        return userList;
                    }),
                        switchMap(userList => [
                            new LoadUsersSuccessAction(userList)
                        ])
                    );
            }
            return of(new UsersFalseAction());
        })
    );

    @Effect()
    addUser$ = this.actions$.pipe(
        ofType(EUsersActions.ADD_USER),
        withLatestFrom(this.store.select(selectUsers)),
        switchMap((action: any[]) => {
            const user: any = action[0]['payload'];
            const existingUsers: any[] = [];
            for (let index = 0; index < action[1]?.length; index++) {
                const element = action[1][index];
                existingUsers.push(element)
            }
            return this.usersService.allowUser(user)
                .pipe(
                    map((res: any) => {
                        if (res['status'] === true) {
                            existingUsers.push(user)
                        }
                        return existingUsers;
                    }),
                    switchMap(userList => [
                        new AddUserSuccessAction(userList)
                    ])
                );
        })
    );

    @Effect()
    deleteUser$ = this.actions$.pipe(
        ofType(EUsersActions.DELETE_USER),
        withLatestFrom(this.store.select(selectUsers)),
        switchMap((action: any[]) => {
            const userPhone: any = action[0]['payload'];
            const existingUsers: any[] = [];

            for (let index = 0; index < action[1]?.length; index++) {
                const element = action[1][index];
                existingUsers.push(element)
            }

            return this.usersService.disAllowUser(userPhone)
                .pipe(
                    map((res: any) => {
                        if (res['status'] === true) {
                            const index = existingUsers.findIndex((user) => user?.phone_number === userPhone);
                            existingUsers.splice(index, 1);
                        }
                        return existingUsers;
                    }),
                    switchMap(userList => [
                        new DeleteUserSuccessAction(userList)
                    ])
                );
        })
    );
}
