import { Injectable, Injector } from "@angular/core";

import { BaseService } from "app/shared/services/base.service";

import { Store } from "@ngrx/store";
import { IAppState } from "app/store/app.state";
import { selectCurrentBusiness } from "app/store/selectors/accounts.selectors";
import { selectUser } from "app/store/selectors/auth.selectors";

import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class UsersService extends BaseService {
    private usersApi = `users`;
    private currentBusiness: any;
    private businessUuid: string = null;
    private currentBusiness$: Observable<any>;
    private currentUser$: Observable<any>;

    constructor(
        injector: Injector,
        private store: Store<IAppState>,
    ) {
        super(injector);
        this.currentBusiness$ = this.store.select(selectCurrentBusiness);
        this.currentUser$ = this.store.select(selectUser);
    }

    getUsers(): Observable<any> {
        return this.currentUser$.pipe(switchMap(currentUser => {
            const api = `${this.usersApi}?uuid=${currentUser['merchant_uuid']}`;
            return this.get(api);
        }));
    }

    allowUser(user: any): Observable<any> {
        return this.currentBusiness$.pipe(switchMap(currentBusiness => {
            this.currentBusiness = currentBusiness;
            this.businessUuid = this.currentBusiness?.uuid;
            const payload = {
                bid: this.businessUuid,
                role: user['role'],
                phone_number: user['phone_number'],
            };
            return this.post(`${this.usersApi}/allow`, payload);
        }));
    }

    disAllowUser(userPhone: any): Observable<any> {
        return this.currentBusiness$.pipe(switchMap(currentBusiness => {
            this.currentBusiness = currentBusiness;
            this.businessUuid = this.currentBusiness?.uuid;
            const payload = {
                bid: this.businessUuid,
                phone_number: userPhone,
            };
            return this.post(`${this.usersApi}/disallow`, payload);
        }));
    }
}
