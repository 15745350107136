import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { StorageService } from 'app/shared/services/storage.service';

@Injectable()
export class AccountService {
    private accountInfo: any;
    private _user: any;
    private _currentBusiness: any; // current selected business

    private headers: HttpHeaders;
    private apiPath: string = environment.apiPath + 'account/';

    constructor(
        private http: HttpClient,
        private storageService: StorageService) {
        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/json');
    }

    public getAccountInfo() {
        if (this.accountInfo) {
            return this.accountInfo;
        } else {
            return this.updateAccountInfo();
        }
    }

    public updateAccountInfo() {
        this._user = this.storageService.getUser();
        return new Promise((resolve, reject) => {
            this.http.get(this.apiPath + `${this.currentUser.merchant_uuid}/businesses`).subscribe((account: any) => {
                this.accountInfo = {};
                if (account.status && account.data) {
                    const result = account.data;

                    this.accountInfo.businesses = result.businesses;
                    let bizUuid = null;
                    let userUuid = null;

                    bizUuid = this.storageService.getCurrentBusiness();
                    userUuid = this.storageService.getCurrentUserUuid();

                    if (result.businesses.length) {
                        // Get current business index from cookie
                        if (bizUuid && userUuid === result?.merchant?.uuid) {
                            const curBiz = result.businesses.find((x: any) => x.uuid === bizUuid);
                            this.currentBusiness = curBiz ? curBiz : result.businesses[0];
                        } else {
                            this.currentBusiness = result.businesses[0];
                        }
                    }

                    this.accountInfo.merchant = result.merchant;
                    this.currentUser = result.merchant;
                }
                resolve(this.accountInfo);
            });
        })
    }

    get currentUser() {
        return this._user;
    }

    set currentUser(value) {
        this._user = value;
        this.storageService.saveCurrentUserUuid(value?.uuid);
    }

    get currentBusiness() {
        return this._currentBusiness;
    }

    set currentBusiness(value) {
        if (value) {
            this.storageService.saveCurrentBusiness(value?.uuid);
        } else {
            this.storageService.removeCurrentBusiness();
            this.accountInfo = null;
        }

        this._currentBusiness = value;
    }

    get currentBusinessType() {
        if (this.currentBusiness && this.currentBusiness.types && this.currentBusiness.types.length) {
            return this.currentBusiness.types[0]
        }

        return null;
    }

    // Business Subscription
    get subscription() {
        return this.currentBusiness.subscription || {};
    }

    // Business Settings
    get settings() {
        return this.currentBusiness.settings || {};
    }
}
