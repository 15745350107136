import { EAuthActions, AuthActions } from '../actions/auth.actions';

export function authReducer(state: any = null, action: AuthActions) {
    switch (action.type) {
        case EAuthActions.LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
                errorMessage: null
            };

        case EAuthActions.LOGIN_FAILURE:
            return {
                ...state,
                errorMessage: 'Incorrect email and/or password.'
            };

        case EAuthActions.LOGOUT:
            return {
                isAuthenticated: false,
                user: null,
                errorMessage: null
            };

        case EAuthActions.USER_LOAD_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: action.user,
                errorMessage: null
            };

        default:
            return state;
    }
}
