import { Injectable, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import * as uuid from 'uuid';
import S3, { ManagedUpload } from 'aws-sdk/clients/s3';

import { BehaviorSubject, Observable, from, of, throwError } from 'rxjs';
import { switchMap, catchError, map, concatMap, toArray } from 'rxjs/operators';

import Minisearch from 'minisearch';

import { Store } from '@ngrx/store';
import { IAppState } from 'app/store/app.state';
import { selectCurrentBusiness } from 'app/store/selectors/accounts.selectors';

import { UtilService } from '../shared/services';

import { ETables } from '../shared/enums';
import { Product } from './models/product.model';
import { BasePouchDbPagination } from 'app/core/pagination/base-pouchdb-pagination';

import { environment } from 'environments/environment';
import { StorageService } from 'app/shared/services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class ProductService extends BasePouchDbPagination {
    private currentBusiness: any;
    private businessUuid: string = null;
    private branchUuid: string = null;

    private currentBusiness$: Observable<any>;
    private selectFields = [];

    private awsBucketName = environment.AWS.BUCKET_NAME;
    private awsAccessKey = environment.AWS.ACCESS_KEY;
    private awsSecretKey = environment.AWS.SECRET_KEY;
    private awsRegion = environment.AWS.REGION;

    private uploadResponse: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(
        injector: Injector,
        private utilService: UtilService,
        private store: Store<IAppState>,
        protected toastr: ToastrService,
        private storageService: StorageService
    ) {
        super(injector);
        this.currentBusiness$ = this.store.select(selectCurrentBusiness);
        this.selectFields = this.utilService.extractObjectKey(new Product());
    }

    saveProduct(product) {
        return this.currentBusiness$.pipe(
            switchMap((currentBusiness: any) => {
                this.currentBusiness = currentBusiness;
                this.businessUuid = this.currentBusiness?.uuid;
                this.branchUuid = this.storageService.getCurrentBranchUuid();

                product.location = currentBusiness?.location;
                if (!product?.uuid) {
                    product.uuid = uuid.v4();
                }
                const postData = {
                    ...product,
                    table_type: ETables.PRODUCT,
                    business_uuid: currentBusiness?.uuid,
                    branch_uuid: this.branchUuid,
                };

                return from(
                    this.dbService.put(
                        this.utilService.buildKey(
                            ETables.PRODUCT,
                            product.uuid
                        ),
                        postData
                    )
                ).pipe(
                    switchMap((response) => {
                        console.info("Item saved");
                        return of(response);
                    }),
                    catchError((error) => {
                        this.toastr.error(error);
                        return of({ error: error });
                    })
                );
            })
        )
    }

    import(products: Product[]) {
        return this.currentBusiness$.pipe(
            switchMap((currentBusiness: any) => {
                this.currentBusiness = currentBusiness;
                this.businessUuid = this.currentBusiness?.uuid;
                this.branchUuid = this.storageService.getCurrentBranchUuid();

                return from(products).pipe(
                    concatMap(product => {
                        if (!product?.uuid) {
                            product.uuid = uuid.v4();
                        }
                        const postData = {
                            ...product,
                            table_type: ETables.PRODUCT,
                            business_uuid: currentBusiness?.uuid,
                            branch_uuid: this.branchUuid,
                        }
                        return from(this.dbService.put(this.utilService.buildKey(ETables.PRODUCT, product.uuid), postData));
                    }),
                    toArray(),
                );
            }),
        );
    }

    getProducts() {
        return this.currentBusiness$.pipe(
            switchMap((currentBusiness: any) => {
                this.currentBusiness = currentBusiness;
                this.businessUuid = this.currentBusiness?.uuid;

                const conditions = {
                    table_type: ETables.PRODUCT,
                    business_uuid: this.businessUuid,
                };
                return from(
                    this.dbService.find(conditions)
                ).pipe(
                    map(result => result.docs),
                    map((products: Product[]) => {
                        // Natural alphabetical sort
                        return products.sort((a: Product, b: Product) => a.name.localeCompare(b.name, 'en', { numeric: true, sensitivity: 'base' }));
                    })
                );
            })
        );
    }

    getPagedProducts() {
        return this.currentBusiness$.pipe(
            switchMap((currentBusiness: any) => {
                this.currentBusiness = currentBusiness;
                this.businessUuid = this.currentBusiness?.uuid;

                this.query = `${ETables.PRODUCT} ${this.businessUuid}`;
                this.fields = ['table_type', 'business_uuid'];
                this.filter = null;

                return this.mapData();
            }),
            map((products: Product[]) => {
                return products.sort((a: Product, b: Product) => a.created_at > b.created_at ? 1 : a.created_at < b.created_at ? -1 : 0);
            })
        );
    }

    getProductById(id: string) {
        return from(this.dbService.get(id));
    }

    search(searchTerm: string, page = 1) {
        this.getProducts().pipe(
            map(products => {
                let minisearch = new Minisearch({
                    idField: '_id',
                    fields: ['name', 'code'],
                    storeFields: this.selectFields
                });
                minisearch.addAll(products);
                let results = minisearch.search(searchTerm, { prefix: true, fuzzy: 0.2 }) as unknown as Product[];
                results = <Array<Product>>results;
                return results;
            })
        ).subscribe(results => {
            this.results = results;
            this.totalItems = results?.length || 0;
        });
    }

    searchFulltext(searchTerm: string) {
        this.offset = 0;
        this.skip = 0;
        this.query = `${searchTerm}`;
        this.fields = ['name', 'code'];
        this.filter = (doc) => {
            return doc.table_type === ETables.PRODUCT && doc.business_uuid === this.businessUuid;
        };
        this.pageChanged({ page: 1 });
    }

    delete(doc: any) {
        return from(this.dbService.remove(doc)).pipe(
            // TODO - remove S3 files
            map(res => res.ok),
            catchError((error) => {
                this.toastr.error(error)
                return of({ error: error });
            })
        );
    }

    uploadFile(product: Product, file: File) {
        if (file != null) {
            this._uploadFileToAWS(product, file);
            return this.uploadResponse.asObservable();
        } else {
            return throwError('file is required');
        }
    }

    private _uploadFileToAWS(product: Product, file: File) {
        const contentType = file.type;
        const extension = file.name.split('.').pop();

        const bucket = new S3({
            accessKeyId: this.awsAccessKey,
            secretAccessKey: this.awsSecretKey,
            region: this.awsRegion
        });

        const params = {
            Bucket: this.awsBucketName,
            Key: `Product/${product.uuid}/${uuid.v4()}.${extension}`,
            Body: file,
            ACL: 'public-read',
            ContentType: contentType
        };

        bucket.upload(params, (err, data: ManagedUpload.SendData) => {
            if (err) {
                console.log('There was an error uploading your file', err);
                return false;
            }
            // console.log('Successfully uploaded file', data);

            this.uploadResponse.next(data.Location);
            return this.uploadResponse;
        });
    }
}
