import { EPartyType } from "../enums";

export class Party {

    constructor() { }
    _id: string = null;
    _rev: string = null;

    uuid: string = null;
    name: string = null;
    business_uuid: string = null;
    branch_uuid: string = null;

    party_type: string = EPartyType.CUSTOMER;
    group_uuid: string = null;

    phone: string = null;
    email: string = null;

    address: string = null;
    shipping_address: string = null;
    tin: string = null;

    current_balance_date: number = Date.now();
    current_balance: number = 0
    can_delete: boolean = true;

    created_at: number = Date.now();
}
