import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { SafeHtmlPipe } from './pipes/safe-html.pipe';

import { BaseService } from './services/base.service';
import { AuthService } from './services/auth.service';
import { StorageService } from './services/storage.service';
import { AccountService } from 'app/account/account.service';
import { PouchdbService } from 'app/services/pouchdb.service';
import { UtilService } from 'app/account/shared/services';
import { SyncGatewayUserService } from 'app/services/sync-gateway-user.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [
    SafeHtmlPipe
  ],
  exports: [
    SafeHtmlPipe
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        BaseService,
        AuthService,
        StorageService,
        AccountService,
        PouchdbService,
        UtilService,
        SyncGatewayUserService
      ]
    }
  }
}
