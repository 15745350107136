import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { Observable, of } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

import { AuthService } from '../../shared/services/auth.service';
import { StorageService } from '../../shared/services/storage.service';

import { IAppState } from '../app.state';
import {
    EAuthActions, LogInAction, LogInSuccessAction,
    LogInFailureAction, UserLoadAction, UserLoadSuccessAction, 
    SendOTPAction, SendOTPSuccessAction, VerifyOTPAction
} from '../actions/auth.actions';
import { SyncUserLoadAction } from '../actions/sync-gateway-user.actions';

@Injectable()
export class AuthEffects {

    constructor(
        private actions: Actions,
        private store: Store<IAppState>,
        private authService: AuthService,
        private router: Router,
        private storageService: StorageService
    ) { }

    @Effect()
    LogIn$: Observable<any> = this.actions.pipe(
        ofType(EAuthActions.LOGIN),
        map((action: LogInAction) => action.payload),
        switchMap(payload => {
            return this.authService.login(payload).pipe(
                map((response) => {
                    this.storageService.saveUser(response.data.user);
                    this.storageService.saveToken(response.data.token);
                    this.storageService.saveRefreshToken(response.data.refresh_token);
                    return new LogInSuccessAction(response.data);
                }),
                catchError((error) => {
                    console.log(error);
                    return of(new LogInFailureAction({ error: error }));
                })
            )
        })
    );

    @Effect()
    SendOTP$: Observable<any> = this.actions.pipe(
        ofType(EAuthActions.SEND_OTP),
        map((action: SendOTPAction) => action.payload),
        switchMap(payload => {
            this.storageService.savePhoneNo(payload);
            return this.authService.sendOTP(payload).pipe(
                map((response) => {
                    return new SendOTPSuccessAction(response);
                }),
                catchError((error) => {
                    console.log(error);
                    return of(new LogInFailureAction({ error: error }));
                })
            )
        }) 
    );

    @Effect({ dispatch: false })
    SendOTPSuccess$: Observable<any> = this.actions.pipe(
        ofType(EAuthActions.SEND_OTP_SUCCESS),
        map((action: SendOTPSuccessAction) => action.payload),
        tap((data) => {
            this.router.navigateByUrl('/auth/otp');
        })
    );

    @Effect()
    VerifyOTP$: Observable<any> = this.actions.pipe(
        ofType(EAuthActions.VERIFY_OTP),
        map((action: VerifyOTPAction) => action.payload),
        switchMap(payload => {
            this.storageService.removePhoneNo();
            return this.authService.verifyOTP(payload).pipe(
                map((response) => {
                    this.storageService.saveUser(response.data.user);
                    this.storageService.saveToken(response.data.token);
                    this.storageService.saveRefreshToken(response.data.refresh_token);
                    return new LogInSuccessAction(response.data);
                }),
                catchError((error) => {
                    console.log(error);
                    return of(new LogInFailureAction({ error: error }));
                })
            )
        })
    );

    @Effect({ dispatch: false })
    LogInSuccess$: Observable<any> = this.actions.pipe(
        ofType(EAuthActions.LOGIN_SUCCESS),
        map((action: LogInSuccessAction) => action.payload),
        tap((data) => {
            this.router.navigateByUrl('/dashboard');
        })
    );

    @Effect({ dispatch: false })
    LogInFailure$: Observable<any> = this.actions.pipe(
        ofType(EAuthActions.LOGIN_FAILURE)
    );

    @Effect({ dispatch: false })
    LogOut$: Observable<any> = this.actions.pipe(
        ofType(EAuthActions.LOGOUT),
        tap((user) => {
            this.storageService.removeUser();
            this.storageService.removeToken();
            this.storageService.removeRefreshToken();
        })
    );

    @Effect()
    loadUser$ = this.actions.pipe(
        ofType<UserLoadAction>(EAuthActions.USER_LOAD),
        switchMap(user => {
            if (user) {
                return of(this._getLoggedInUser());
            } else {
                return of();
            }
        }),
        switchMap((response: any) => {
            const user = response ? response : null;
            this.storageService.saveUser(user);
            if (user) {
                this.store.dispatch(new SyncUserLoadAction());
            }
            return of(new UserLoadSuccessAction(user));
        })
    );

    _getLoggedInUser() {
        const user = this.storageService.getUser();
        if (!!user) {
            return user;
        } else {
            return null;
        }
    }
}
