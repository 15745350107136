import { Injectable, Injector } from '@angular/core';

import { from, of, Observable } from 'rxjs';
import { map, catchError, switchMap, concatMap, toArray } from 'rxjs/operators';

import * as uuid from 'uuid';
import { ToastrService } from 'ngx-toastr';
import Minisearch from 'minisearch';

import { Store } from '@ngrx/store';
import { IAppState } from 'app/store/app.state';
import { selectCurrentBusiness } from 'app/store/selectors/accounts.selectors';

import { Party, PartyGroup } from '../models';
import { ETables } from 'app/account/shared/enums';
import { EPartyType } from '../enums';

import { UtilService } from 'app/account/shared/services/util.service';
import { StorageService } from 'app/shared/services/storage.service';

import { BasePouchDbPagination } from 'app/core/pagination/base-pouchdb-pagination';

@Injectable({
  providedIn: 'root'
})
export class PartyService extends BasePouchDbPagination {

  private currentBusiness: any;
  private businessUuid: string = null;
  private branchUuid: string = null;

  private currentBusiness$: Observable<any>;
  private selectFields = [];

  constructor(
    injector: Injector,
    private utilService: UtilService,
    private store: Store<IAppState>,
    protected toastr: ToastrService,
    private storageService: StorageService,
  ) {
    super(injector);

    this.currentBusiness$ = this.store.select(selectCurrentBusiness);
    this.selectFields = this.utilService.extractObjectKey(new Party());
  }

  getParties() {
    return this.currentBusiness$.pipe(
      switchMap((currentBusiness: any) => {
        this.currentBusiness = currentBusiness;
        this.businessUuid = this.currentBusiness?.uuid;

        const conditions = {
          table_type: ETables.PARTY,
          business_uuid: this.businessUuid,
        };
        return from(
          this.dbService.find(conditions)
        ).pipe(
          map(result => result.docs),
          map((parties: any[]) => {
            // Natural alphabetical sort
            return parties.sort((a: any, b: any) => a.name.localeCompare(b.name, 'en', { numeric: true, sensitivity: 'base' }));
          })
        );
      })
    );
  }

  getSuppliers() {
    return this.getParties().pipe(
      concatMap((parties: any[]) => {
        const suppliers = parties.filter(party => {
          return party.party_type === EPartyType.SUPPLIER;
        });
        return of(suppliers);
      })
    );
  }

  getCustomers() {
    return this.getParties().pipe(
      concatMap((parties: any[]) => {
        const customers = parties.filter(party => {
          return party.party_type === EPartyType.CUSTOMER;
        });
        return of(customers);
      })
    );
  }

  getPagedParties() {
    return this.currentBusiness$.pipe(
      switchMap((currentBusiness: any) => {
        this.currentBusiness = currentBusiness;
        this.businessUuid = this.currentBusiness?.uuid;
        this.query = `${ETables.PARTY} ${this.businessUuid}`;
        this.fields = ['table_type', 'business_uuid'];
        return this.mapData();
      }),
      map((parties: any[]) => {
        // Natural alphabetical sort
        return parties.sort((a: any, b: any) => a.name.localeCompare(b.name, 'en', { numeric: true, sensitivity: 'base' }));
      })
    );
  }

  saveParty(party) {
    return this.currentBusiness$.pipe(
      switchMap((currentBusiness: any) => {
        this.currentBusiness = currentBusiness;
        this.businessUuid = this.currentBusiness?.uuid;
        this.branchUuid = this.storageService.getCurrentBranchUuid();

        if (!party.uuid) {
          party.uuid = uuid.v4();
        }
        if (!party.business_uuid) {
          party.business_uuid = this.businessUuid;
        }
        if (!party.branch_uuid) {
            party.branch_uuid = this.branchUuid;
        }
        const postData = {
          table_type: ETables.PARTY,
          ...party
        }

        return from(this.dbService.put(this.utilService.buildKey(ETables.PARTY, party.uuid), postData)).pipe(
          switchMap((response: any) => {
            this.toastr.success('Party saved');
            return from(this.dbService.get(response._id));
          }),
          catchError((error) => {
            this.toastr.error(error)
            return of({ error: error });
          })
        );
      })
    );
  }

  import(parties: Party[]) {
    return this.currentBusiness$.pipe(
      switchMap((currentBusiness: any) => {
        this.currentBusiness = currentBusiness;
        this.businessUuid = this.currentBusiness?.uuid;

        return from(parties).pipe(
          concatMap(party => {

            if (!party.uuid) {
              party.uuid = uuid.v4();
            }
            if (!party.business_uuid) {
              party.business_uuid = this.businessUuid;
            }
            const postData = {
              table_type: ETables.PARTY,
              ...party
            }

            return from(this.dbService.put(this.utilService.buildKey(ETables.PARTY, party.uuid), postData));
          }),
          toArray(),
        );
      }),
    );
  }

  savePartyGroup(group) {
    return this.currentBusiness$.pipe(
      switchMap((currentBusiness: any) => {
        this.currentBusiness = currentBusiness;
        this.businessUuid = this.currentBusiness?.uuid;
        this.branchUuid = this.storageService.getCurrentBranchUuid();

        if (!group.uuid) {
          group.uuid = uuid.v4();
        }
        if (!group.business_uuid) {
          group.business_uuid = this.businessUuid;
        }
        const postData = {
          table_type: ETables.PARTY_GROUP,
          ...group
        }
        return from(this.dbService.put(this.utilService.buildKey(ETables.PARTY_GROUP, group.uuid), postData));
      })
    );
  }

  getPartyGroups() {
    return this.currentBusiness$.pipe(
      switchMap((currentBusiness: any) => {
        this.currentBusiness = currentBusiness;
        this.businessUuid = this.currentBusiness?.uuid;

        const group = new PartyGroup();
        const params: any = {
          fields: this.utilService.extractObjectKey(group),
          selector: { table_type: ETables.PARTY_GROUP, business_uuid: this.businessUuid }
        };
        return from(this.dbService.list(params.fields, params.selector)).pipe(map(data => data.docs));
      })
    );
  }

  getPartyById(id: string) {
    return from(this.dbService.get(id));
  }

  getPartyName(parties: any[] = [], uuid: string) {
    const party = parties.find(party => {
      return uuid === party.uuid;
    }) || null;

    return party ? party.name : 'Not Available';
  }

  search(searchTerm: string, page = 1) {
    this.getParties().pipe(
      map(parties => {
        let minisearch = new Minisearch({
          idField: '_id',
          fields: ['name', 'phone'],
          storeFields: this.selectFields
        });
        minisearch.addAll(parties);
        let results = minisearch.search(searchTerm, { prefix: true, fuzzy: 0.2 }) as unknown as Party[];
        results = <Array<Party>>results;
        return results;
      })
    ).subscribe(results => {
      this.results = results;
      this.totalItems = results?.length || 0;
    });
  }

  searchFulltext(searchTerm: string) {
    this.offset = 0;
    this.skip = 0;
    this.query = `${searchTerm}`;
    this.fields = ['name', 'phone'];
    this.filter = (doc) => {
      return doc.table_type === ETables.PARTY && doc.business_uuid === this.businessUuid;
    };
    this.pageChanged({ page: 1 });
  }

  delete(doc: any) {
    return from(this.dbService.remove(doc)).pipe(
      map(res => res.ok),
      catchError((error) => {
        this.toastr.error(error)
        return of({ error: error });
      })
    );
  }
}
