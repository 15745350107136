import { ItemSettingActions, ESettingsActions } from '../actions/settings.actions';
import { Settings } from 'app/account/settings/models/settings.model';

export const initialState = {
    settings: new Settings(),
    errorMessage: null
}

export function settingsReducer(state = initialState, action: ItemSettingActions) {
    switch (action.type) {
        case ESettingsActions.SAVE_ITEM_SETTINGS:
            return {
                ...state,
                settings: action.payload,
                errorMessage: null
            };

        case ESettingsActions.SAVE_ITEM_SETTINGS_FAIL:
            return {
                ...state,
                settings: {
                    item_settings: null,
                },
                errorMessage: 'Item settings save failed'
            };

        case ESettingsActions.SAVE_ITEM_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.payload,
                errorMessage: null
            };

        case ESettingsActions.SAVE_PARTY_SETTINGS:
            return {
                ...state,
                settings: action.payload,
                errorMessage: null
            };

        case ESettingsActions.SAVE_TRANSACTION_SETTINGS:
            return {
                ...state,
                settings: action.payload,
                errorMessage: null
            };

        case ESettingsActions.SAVE_TRANSACTION_SETTINGS_FAIL:
            return {
                ...state,
                settings: {
                    transaction_settings: null,
                },
                errorMessage: 'Trasaction settings save failed'
            };

        case ESettingsActions.SAVE_PARTY_SETTINGS_FAIL:
            return {
                ...state,
                settings: {
                    party_settings: null,
                },
                errorMessage: 'Party settings save failed'
            };

        case ESettingsActions.SAVE_PARTY_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.payload,
                errorMessage: null
            };


        case ESettingsActions.SAVE_EXPORT_SETTINGS_FAIL:
            return {
                ...state,
                settings: {
                    export_settings: null,
                },
                errorMessage: 'Export settings save failed'
            };

        case ESettingsActions.SAVE_EXPORT_SETTINGS_FAIL:
            return {
                ...state,
                settings: {
                    export_settings: null,
                },
                errorMessage: 'Export settings save failed'
            };

        case ESettingsActions.SAVE_EXPORT_SETTINGS_SUCCESS:
            return {
                ...state,
                export_settings: action.payload,
                errorMessage: null
            };


        case ESettingsActions.LOAD_ITEM_SETTINGS:
            return {
                ...state,
                settings: null,
                errorMessage: null
            };

        case ESettingsActions.LOAD_ITEM_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.payload,
                errorMessage: null
            };
        case ESettingsActions.LOAD_SETTINGS:
            return {
                ...state,
                settings: null,
                errorMessage: null
            };

        case ESettingsActions.LOAD_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.payload,
                errorMessage: null
            };

        default:
            return state;
    }
}
