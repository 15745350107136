import { Injectable, Injector } from '@angular/core';

import { from, Observable, of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import * as uuid from 'uuid';
import { ToastrService } from 'ngx-toastr';

import { Store } from '@ngrx/store';
import { IAppState } from 'app/store/app.state';

import { TransactionItem } from '../models';
import { ETables } from 'app/account/shared/enums';
import { BasePouchDbList } from 'app/core/base-pouchdb-list';

import { UtilService } from 'app/account/shared/services';
import { selectCurrentBusiness } from 'app/store/selectors/accounts.selectors';

@Injectable({
  providedIn: 'root'
})
export class TransactionItemService extends BasePouchDbList {

  private currentBusiness: any;
  private businessUuid: string = null;
  private currentBusiness$: Observable<any>;


  constructor(
    injector: Injector,
    private utilService: UtilService,
    private store: Store<IAppState>,
    protected toastr: ToastrService,
  ) {
    super(injector);
    this.currentBusiness$ = this.store.select(selectCurrentBusiness);
  }

  saveTransactionItem(transactionItem: TransactionItem) {
    transactionItem.uuid = transactionItem.uuid ? transactionItem.uuid : uuid.v4();
    const postData = {
      ...transactionItem,
      table_type: ETables.TRANSACTION_ITEM,
    }
    return from(this.dbService.put(this.utilService.buildKey(ETables.TRANSACTION_ITEM, transactionItem.uuid), postData));
  }

  getTransactionItems(conditionParams: any) {
    return this.currentBusiness$.pipe(
      switchMap((currentBusiness: any) => {
        this.currentBusiness = currentBusiness;
        this.businessUuid = this.currentBusiness?.uuid;

        const conditions = {
          table_type: ETables.TRANSACTION_ITEM,
          business_uuid: this.businessUuid,
          ...conditionParams
        };

        return from(
          this.dbService.find(conditions)
        ).pipe(
          map(result => result.docs)
        );
      })
    );
  }

  deleteTransactionItem(doc: any) {
    return from(this.dbService.remove(doc)).pipe(
      map(res => res.ok),
      catchError((error) => {
        this.toastr.error(error)
        return of({ error: error });
      })
    );
  }
}
