import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
    ESyncUserActions,
    SyncUserCreateAction,
    // SyncUserCreateSuccessAction,
    SyncUserCreateFailAction,
    SyncUserFetchAction,
    SyncUserFetchSuccessAction,
    SyncUserFetchFailAction
} from '../actions/sync-gateway-user.actions';

import { StorageService } from 'app/shared/services/storage.service';
import { SyncGatewayUserService } from 'app/services/sync-gateway-user.service';

@Injectable()
export class SyncGatewayUserEffects {

    private user = null;

    constructor(
        public storageService: StorageService,
        public syncUserService: SyncGatewayUserService,
        private action$: Actions) { }

    @Effect()
    loadSyncUser$ = this.action$.pipe(
        ofType(ESyncUserActions.SYNC_USER_LOAD),
        switchMap(() => {
            const syncUser = this.storageService.getSyncGatewayUser();
            if (syncUser != null) {
                return of(new SyncUserFetchSuccessAction(syncUser));
            } else {
                return of(new SyncUserFetchAction());
            }
        })
    );

    @Effect()
    createSyncUser$ = this.action$.pipe(
        ofType(ESyncUserActions.SYNC_USER_CREATE),
        tap(() => {
            this.user = this.storageService.getUser();
        }),
        switchMap(() => {
            const name = this.user.phone;

            return this.syncUserService.create(name).pipe(
                switchMap((response: any) => {
                    if (response) {
                        response = { ...response }
                    }
                    if (response.status == true) {
                        return of(new SyncUserFetchAction());
                    } else {
                        return of(new SyncUserCreateFailAction(response ? response.data : null));
                    }
                }),
                catchError((error) => {
                    return of(new SyncUserCreateFailAction({ error: error }));
                })
            );
        })
    );

    @Effect()
    fetchSyncUser$ = this.action$.pipe(
        ofType(ESyncUserActions.SYNC_USER_FETCH),
        tap(() => {
            this.user = this.storageService.getUser();
        }),
        switchMap(() => {
            const name = this.user.phone;

            return this.syncUserService.getUser(name).pipe(
                switchMap((response: any) => {
                    if (response) {
                        response = { ...response }
                    }

                    if (response.status == true) {
                        const syncUser = response.data;
                        this.storageService.setSyncGatewayUser(syncUser);
                        return of(new SyncUserFetchSuccessAction(syncUser));
                    } else {
                        return of(new SyncUserCreateAction());
                    }
                }),
                catchError((error) => {
                    return of(new SyncUserCreateAction());
                    // return of(new SyncUserFetchFailAction({ error: error }));
                })
            );
        })
    );
}