import { ApplicationRef, Component, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Gtag } from 'angular-gtag';

import { GoogleAnalyticsService } from './services/google-analytics.service';
import { ConnectionService } from './shared/services/connection.service';
import { StorageService } from './shared/services/storage.service';

import { IAppState } from './store/app.state';
import { UserLoadAction } from './store/actions/auth.actions';
import { LoadCatalogAction } from './store/actions/catalog.actions';
import { LoadSettingsAction } from './store/actions/settings.actions';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'body',
    template: `<router-outlet></router-outlet>`
})
export class AppComponent implements OnDestroy {

    public isConnected: boolean = true;
    private destroyed = new Subject<any>();

    constructor(public router: Router,
        public googleAnalytics: GoogleAnalyticsService,
        private translate: TranslateService,
        private connection: ConnectionService,
        private storageService: StorageService,
        private store: Store<IAppState>,
        private swUpdate: SwUpdate,
        private appRef: ApplicationRef,
        private gtag: Gtag) {
        this.updateClient();
        this.checkUpdate();
        this.gtag.pageview({ page_title: 'Ledger Manager' });
    }

    ngOnInit() {
        this.store.dispatch(new UserLoadAction());
        if (this.storageService.getCurrentBusiness()) {
            this.store.dispatch(new LoadCatalogAction());
            this.store.dispatch(new LoadSettingsAction());
        }

        const lang = window.localStorage.getItem('lang');
        this.translate.use(lang || 'bn');

        this.connection.monitor().pipe(
            takeUntil(this.destroyed),
        ).subscribe(connected => {
            this.isConnected = connected;
        });
    }

    updateClient() {
        if (!this.swUpdate.isEnabled) {
            console.log('Not Enabled');
            return;
        }
        this.swUpdate.available.subscribe((event) => {
            if (confirm('Update available for the app, please confirm')) {
                this.swUpdate.activateUpdate().then(() => location.reload());
            }
        });

        this.swUpdate.activated.subscribe((event) => {
            console.log(`current`, event.previous, `available `, event.current);
        });
    }

    checkUpdate() {
        this.appRef.isStable.subscribe((isStable) => {
            if (isStable) {
                const timeInterval = interval(8 * 60 * 60 * 1000);

                timeInterval.subscribe(() => {
                    this.swUpdate.checkForUpdate().then(() => console.log('checked'));
                    console.log('update checked');
                });
            }
        });
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
