import { createSelector } from '@ngrx/store';

import { CatalogState, IAppState } from '../app.state';
export const selectCatalog = (state: IAppState) => state.catalog;


export const selectProducts = createSelector(
    selectCatalog,
    (state: CatalogState) => state?.products
);

export const selectParties = createSelector(
    selectCatalog,
    (state: CatalogState) => state?.parties
);