export enum ETransactionType {
    PURCHASE = 'purchase',
    SALES = 'sales',
    PURCHASE_RETURN = 'purchase_return',
    SALES_RETURN = 'sales_return',
    PAYMENT_IN = 'payment_in',
    PAYMENT_OUT = 'payment_out',
}

export enum EPaymentType {
    CASH = 'Cash',
    CHEQUE = 'Cheque',
}