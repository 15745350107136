import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/first';
import 'rxjs/add/operator/map';

import { environment } from '../environments/environment';

@Injectable()
export class UriResolver implements Resolve<any> {
    private businessId: string;

    constructor(private http: HttpClient, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        let path = state.url.split('/')[1];

        return this.http.get(environment.apiPath + 'business/slug/' + path).map((res: any) => {
            var biz = res;

            if (biz.status && biz.data) {
                return { id: biz.data, slug: path }
            } else {
                this.router.navigate(['/not-found']);
                return false;
            }
        }).first();
    }
}
