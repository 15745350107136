import { Injectable, Injector } from '@angular/core';

import { map, tap, catchError } from 'rxjs/operators';

import { BaseService } from 'app/shared/services/base.service';

@Injectable()
export class AuthService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  login(loginData) {
    return this.post('login', loginData).pipe(
      tap(response => {
        const res = response.data;
        if (res && res.token !== undefined) {
          const user = res.user;
          this.storageService.saveUser(user);
          this.storageService.saveToken(res.token);
          this.storageService.saveRefreshToken(res.refresh_token);
        }
      }),
      map(response => response)
    );
  }

  registrationOTP(registrationData) {
    return this.post('register/otp', registrationData).pipe(
      tap(response => {
        if (response && response.token !== undefined) {
          this.storageService.saveToken(response.token);
        }
      }),
      map(response => response)
    );
  }

  registrationOTPVerify(otpData) {
    return this.post('register/otp-verify', otpData);
  }

  sendOTP(otpData) {
    return this.post('otp', { phone_number: otpData })
  }

  verifyOTP(otpData) {
    return this.post('otp-verify', otpData).pipe(
      tap(response => {
        const res = response.data;
        if (res && res.token !== undefined) {
          const user = res.user;
          this.storageService.saveUser(user);
          this.storageService.saveToken(res.token);
          this.storageService.saveRefreshToken(res.refresh_token);
        }
      }),
      map(response => response)
    );
  }

  logout() {
    return this.delete('logout').pipe(
      tap(response => {
        this.storageService.destroyAll();
      }),
      map(response => response),
      catchError(error => {
        return error;
      })
    );
  }
}
