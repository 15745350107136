import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { Gtag } from "angular-gtag";


@Injectable()
export class GoogleAnalyticsService {

    public productCategory: string = 'Product';
    public partyCategory: string = 'Party';
    public purchaseCategory: string = 'Purchase';
    public salesCategory: string = 'Sales';
    public expenseCategory: string = 'Expense';
    public transactionCategory: string = 'Transaction';
    public reportCategory: string = 'Report';
    public subscriptionCategory: string = 'Subscription';

    public addProductEvent: string = "add_product_event";
    public deleteProductEvent: string = "delete_product_event";

    public addPartyEvent: string = "add_party_event";
    public deletePartyEvent: string = "delete_party_event";

    public addSalesEvent: string = "add_sales_event";
    public deleteSalesEvent: string = "delete_sales_event";

    public addPurchaseEvent: string = "add_purchase_event";
    public deletePurchaseEvent: string = "delete_purchase_event";

    public addSalesReturnEvent: string = "add_sales_return_event";
    public deleteSalesReturnEvent: string = "delete_sales_return_event";

    public addPurchaseReturnEvent: string = "add_purchase_return_event";
    public deletePurchaseReturnEvent: string = "delete_purchase_return_event";

    public addExpenseEvent: string = "add_expense_event";
    public deleteExpenseEvent: string = "delete_expense_event";

    public addTransactionEvent: string = "add_transaction_event";
    public deleteTransactionEvent: string = "delete_transaction_event";

    public addSubscriptionEvent: string = "add_subscription_event";
    public removeSubscriptionEvent: string = "remove_subscription_event";

    public addProductImportEvent: string = "add_product_import_event";
    public addPartyImportEvent: string = "add_party_import_event";

    public viewPurchaseReportEvent: string = "view_purchase_report_event";
    public viewSalesReportEvent: string = "view_sales_report_event";
    public viewStockSummaryReportEvent: string = "view_stock_summary_report_event";
    public viewStockItemDetailsReportEvent: string = "view_stock_item_details_report_event";
    public viewPartyWiseItemReport: string = "view_party_wise_item_report";

    public viewAllPartiesReportEvent: string = "view_all_parties_report_event";
    public viewPartyStatementReportEvent: string = "view_party_statement_report_event";
    public viewItemWisePartyReportEvent: string = "view_item_wise_party_report_event";
    public viewPartyWiseProfitLossReportEvent: string = "view_party_wise_profit_loss_report_event";
    public viewPartyWiseSalePurchaseReportEvent: string = "view_partywise_sale_purchase_report_event";

    public viewCashflowReportEvent: string = "view_cashflow_report_event";
    public viewDayBookReportEvent: string = "view_daybook_report_event";
    public viewProfitLossReportEvent: string = "view_profit_loss_report_event";

    public viewExpenseTransactionReportEvent: string = "view_expense_transaction_report_event";
    public viewExpenseCategoryReportEvent: string = "view_expense_category_report_event";
    public viewExpenseItemReportEvent: string = "view_expense_item_report_event";

    public generateSalesReportEvent: string = "generate_sales_report_event";
    public generatePurchaseReportEvent: string = "generate_purchase_report_event";
    public generateProfitLossReportEvent: string = "generate_profit_loss_report_event";
    public generateDayBookReportEvent: string = "generate_daybook_report_event";
    public generateCashFlowReportEvent: string = "generate_cashflow_report_event";

    public generateStockSummaryReportEvent: string = "generate_stock_summary_report_event";
    public generateItemDetailsReportEvent: string = "generate_item_details_report_event";
    public generatePartyWiseItemReportEvent: string = "generate_party_wise_item_report_event";

    public generateAllPartyReportEvent: string = "generate_all_party_report_event";
    public generateItemWisePartyReportEvent: string = "generate_item_wise_party_report_event";
    public generatePartyStatementReportEvent: string = "generate_party_statement_report_event";
    public generatePartyWiseProfitLossReportEvent: string = "generate_party_wise_profit_loss_report_event";
    public generatePartyWiseSalePurchaseReportEvent: string = "generate_party_wise_sale_purchase_report_event";

    public generateExpenseTransactionReportEvent: string = "generate_expense_transaction_report_event";
    public generateExpenseCategoryReportEvent: string = "generate_expense_category_report_event";
    public generateExpenseItemReportEvent: string = "generate_expense_item_report_event";


    constructor(@Inject(PLATFORM_ID) private platformId: Object, private gtag: Gtag) { }

    public emitEvent(
        eventCategory: string,
        eventAction: string,
        eventLabel?: string,
        eventValue?: number
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.gtag.event(eventAction, {
                event_category: eventCategory,
                event_label: eventLabel,
                value: eventValue
            });
        };
    }
}
