import { Action } from '@ngrx/store';

export enum EAccountActions {
    ACCOUNT_INFO_FETCH = "ACCOUNT_INFO_FETCH",
    ACCOUNT_INFO_GET_SUCCESS = "ACCOUNT_INFO_GET SUCCESS",
    ACCOUNT_INFO_GET_FAIL = "ACCOUNT_INFO_GET FAIL"
}

export class AccountInfoFetchAction implements Action {
    readonly type = EAccountActions.ACCOUNT_INFO_FETCH;
    constructor() { }
}

export class AccountInfoFetchSuccessAction implements Action {
    readonly type = EAccountActions.ACCOUNT_INFO_GET_SUCCESS;
    constructor(public payload: any) { }
}

export class AccountInfoFetchFailAction implements Action {
    readonly type = EAccountActions.ACCOUNT_INFO_GET_FAIL;
    constructor(public payload: any) { }
}

export type AccountsActions = AccountInfoFetchAction
    | AccountInfoFetchSuccessAction
    | AccountInfoFetchFailAction;
