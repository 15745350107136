import { Action } from '@ngrx/store';

export enum EAuthActions {
    USER_LOAD = '[Auth] User Load',
    USER_LOAD_SUCCESS = '[Auth] User Load Success',
    LOGIN = '[Auth] Login',
    LOGIN_SUCCESS = '[Auth] Login Success',
    LOGIN_FAILURE = '[Auth] Login Failure',
    LOGOUT = '[Auth] Logout',
    SEND_OTP = '[Auth] Send OTP',
    SEND_OTP_SUCCESS = '[Auth] Send OTP Success',
    VERIFY_OTP = '[Auth] Verify OTP',
}

export class LogInAction implements Action {
    readonly type = EAuthActions.LOGIN;
    constructor(public payload: any) { }
}

export class SendOTPAction implements Action {
    readonly type = EAuthActions.SEND_OTP;
    constructor(public payload: any) { }
}

export class SendOTPSuccessAction implements Action {
    readonly type = EAuthActions.SEND_OTP_SUCCESS;
    constructor(public payload: any) { }
}

export class VerifyOTPAction implements Action {
    readonly type = EAuthActions.VERIFY_OTP;
    constructor(public payload: any) { }
}

export class LogInSuccessAction implements Action {
    readonly type = EAuthActions.LOGIN_SUCCESS;
    constructor(public payload: any) { }
}

export class LogInFailureAction implements Action {
    readonly type = EAuthActions.LOGIN_FAILURE;
    constructor(public payload: any) { }
}

export class LogOutAction implements Action {
    readonly type = EAuthActions.LOGOUT;
}

export class UserLoadAction implements Action {
    public readonly type = EAuthActions.USER_LOAD;
    constructor() { }
}

export class UserLoadSuccessAction implements Action {
    public readonly type = EAuthActions.USER_LOAD_SUCCESS;
    constructor(public user: any) { }
}

export type AuthActions =
    LogInAction
    | LogInSuccessAction
    | LogInFailureAction
    | LogOutAction
    | UserLoadAction
    | UserLoadSuccessAction
    | SendOTPAction
    | SendOTPSuccessAction
    | VerifyOTPAction
