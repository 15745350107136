import { Injectable } from '@angular/core';

import { DatePipe } from '@angular/common';

import * as uuid from 'uuid';
import { customAlphabet } from 'nanoid';

import { Party } from 'app/account/party/models';

import { environment } from 'environments/environment';

@Injectable()
export class UtilService {
  constructor(private datePipe: DatePipe) { }

  capitalize(input: string) {
    return input.split(' ').map(p => {
      return p.charAt(0).toUpperCase() + p.slice(1);
    }).join(' ');
  }

  buildKey(table_type: string, uuidParam?: string) {
    if (!uuidParam) {
      return `${table_type}::${uuid.v4()}`;
    } else {
      return `${table_type}::${uuidParam}`;
    }
  }

  extractObjectKey(targetObject: object) {
    let keys: string[] = [];
    for (const key in targetObject) {
      if (targetObject.hasOwnProperty(key)) {
        keys.push(key)
      }
    }
    return keys;
  }

  dateToMilliSeconds(date: string) {
    return new Date(date).getTime();
  }

  // if user copy paste string with special char, this function will remove them when saving
  sanitizeText(str) {
    str = str.replace(/[^a-z0-9áéíóúñü \.,_-]/gim,"");
    return str.trim();
  }

  getPartyNameByUuid(uuid: string, parties: Party[] = []) {
    const party = parties.find(item => {
      return uuid === item.uuid;
    });

    return party ? party?.name : null;
  }

  getProductNameByUuid(uuid: string, items: any[] = []) {
    const item = items.find(item => {
      return uuid === item.uuid;
    });

    return item ? item?.name : null;
  }

  getTransactionType(transactionType: string) {
    const arr = transactionType.split("_");

    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

    }
    return arr.join(" ");
  }

  getInitialStockByUuid(uuid: string, items: any[] = []) {
    const item = items.find(item => {
      return uuid === item.uuid;
    });

    return item ? item?.stock_count : 0;
  }

  getItemVATByUuid(uuid: string, items: any[] = []) {
    const item = items.find(item => {
      return uuid === item.uuid;
    });

    return item ? item?.item_wise_tax : 0;
  }

  generateUniqueNo() {
    const nanoid = customAlphabet('1234567890abcdef', 14)
    return nanoid();
  }
}
