import { Injector, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable, throwError } from 'rxjs';
import { tap, catchError } from "rxjs/operators";

import { ToastrService } from 'ngx-toastr';

import { StorageService } from './storage.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class BaseService {
    private _apiPrefix = environment.apiPath;
    protected apiPath = '';


    protected http: HttpClient;
    protected storageService: StorageService;
    protected toastr: ToastrService

    protected headers;

    protected successFunction = (response: any) => {
        if (response.status === true && response.message !== 'success') {
            this.toastr.success(response.message);
        }
    };

    protected errorFunction = (res: any) => {
        const response = res.error;
        if (response.status === false && response.message) {
            this.toastr.error(response.message);
        }
        return throwError(response);
    };

    constructor(protected injector: Injector) {
        this.http = injector.get(HttpClient);
        this.storageService = injector.get(StorageService);
        this.toastr = injector.get(ToastrService);
        this.init();
    }

    // TODO - remove later
    public get apiBaseUrl() {
        return this._apiPrefix;
    }

    protected get apiUrl() {
        return this._apiPrefix; // + this.apiPath + '/';
    }

    private getHeaders() {
        const headerOptions: any = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        const token = this.storageService.getToken();
        if (token) {
            headerOptions['Authorization'] = token;
        }

        const headers = new HttpHeaders(headerOptions);
        return headers;
    }

    protected get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
        const headers = this.getHeaders();
        return this.http.get(`${this.apiUrl}${path}`, { params, headers }).pipe(
            tap(this.successFunction),
            catchError(this.errorFunction),
        );
    }

    protected put(path: string, body: any = {}): Observable<any> {
        const headers = this.getHeaders();
        return this.http.put(`${this.apiUrl}${path}`, JSON.stringify(body), {
            headers
        }).pipe(
            tap(this.successFunction),
            catchError(this.errorFunction),
        );
    }

    protected post(path: string, body: any = {}): Observable<any> {
        const headers = this.getHeaders();
        return this.http.post(`${this.apiUrl}${path}`, JSON.stringify(body), {
            headers
        }).pipe(
            tap(this.successFunction),
            catchError(this.errorFunction),
        );
    }

    protected delete(path, params: any = {}): Observable<any> {
        const headers = this.getHeaders();
        return this.http.delete(`${this.apiUrl}${path}`, { params, headers }).pipe(
            tap(this.successFunction),
            catchError(this.errorFunction),
        );
    }

    // TODO - remove later
    protected init() { }

    protected postFile(path: string, field: string, fileToUpload: File, payload: any = null): Observable<any> {
        const formData: FormData = new FormData();
        formData.append(field, fileToUpload, fileToUpload.name);

        if (payload) {
            const keys = Object.keys(payload);

            keys.forEach(key => {
                formData.append(key, payload[key]);
            });
        }

        const token = this.storageService.getToken();
        const headerOptions = {};
        if (token) {
            headerOptions['Authorization'] = token;
        }
        const headers = new HttpHeaders(headerOptions);

        return this.http.post(`${this.apiBaseUrl}${path}`, formData, { headers });
    }
}
