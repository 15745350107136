import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { AuthGuard } from './guards/auth.guard';
import { NotAuthenticatedGuard } from './guards/not-authenticated.guard';

const routes: Routes = [
    {
        path: '', loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
        canActivate: [AuthGuard],
    },
    { path: 'not-found', component: PageNotFoundComponent },
    {
        path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
        canActivate: [NotAuthenticatedGuard]
    },
    { path: '**', component: PageNotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
